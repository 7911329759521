import React from "react"
import {
  MissingPageContainer,
  MissingPageReturnLink,
  MissingPageText,
} from "../templates/MissingPageStyles"
import { coverColor } from "../static/AnilinkConstants/AnilinkConstants"
import SEO from "../components/seo"

const MissingPage = () => {
  return (
    <MissingPageContainer>
    <SEO subtitle={"404"}/>
      <MissingPageText>
        Oops, looks like you hit a dead end; this page doesn't exist yet!
      </MissingPageText>
      <MissingPageReturnLink to="/" cover bg={coverColor} direction="right">
        &lt; Return to homepage
      </MissingPageReturnLink>
    </MissingPageContainer>
  )
}

export default MissingPage
