import styled from "styled-components"
import { mcInternalLink, mch1 } from "../static/type/Typography"

export const MissingPageContainer = styled.div`
  margin: auto;
  font-family: "PT Sans", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const MissingPageText = styled(mch1)`
  font-family: "PT Sans", sans-serif;
`

export const MissingPageReturnLink = styled(mcInternalLink)``
